.icon{
    color: rgb(0, 0, 0);
   /* background-color: #198754;  */
    text-shadow: 3px 3px 4px #ccc;
    font-size: 2.5em;
    text-align: center;
    text-decoration: none;
}
.faicon{
    color: #198754;
    text-shadow: 3px 3px 4px #ccc;
    font-size: 3.5em;
    text-align: center;
    text-decoration: none;
}
.links{
   
    text-decoration: none;
    margin: 15px;
    color: rgb(98, 193, 99);
    /* text-shadow: 1px 1px 1px #ccc; */
    font-size: 1.1em;
    text-align: center;
    color: #198754;
    font-style: normal;
    font-weight: 600;
}
.grid{
   padding: 10px;
   
}
.card2{
background-color:rgb(98, 193, 99) ;
}
.text{
    font: 1em sans-serif;
    text-align: justify;
    padding: 5px;
    margin: 5px;
}
.topbar{
    /* background-color: #198754; */
    color: #198754;
padding: 15px;
background: #fff;
text-align: left;
max-height: 65px;
/* margin: 10px; */
}

hr{
    border:         3px;
    border-left:    1px solid hsla(200, 10%, 50%,100);
    height:         100;
    width:          1px;   
    color: #198754;
}
.bg-green {
    background-color: #d9dfdc !important;
    font-family: "Number";
}
.MuiCollapse-wrapperInner {
    width: 100%;
    height: 100%;
}