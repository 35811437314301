body {
  background: #f1f1f1;
}

.Toast {
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.025rem 0.025rem 0.15rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem auto;
  width: 90%;
}

.Toast--Networking {
  border-color: #008554;
}

.Toast--Capacity {
  border-color: #FFC627;
}

.Toast--Consultancy {
  border-color: #3a59e2;  
}

.Toast__message-category {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-transform: capitalize;
}

.Toast__message-text {
  margin-bottom: 0;
}

.Toast__button {
  background: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  margin-left: 1rem;
  opacity: 0.5;
  padding: 0;
}