.navitems {
    /* color: darkgreen; */
    /* font-size: medium; */
    font-size: 2.8em;
    /* color: blue; */
    margin-top: 5px;
    display: flex;
}

.btn {
    float: right;
    margin: 25px;
}

.row1 {
    padding: 2px;
    width: 100%;
}

.dark2 {
    color: #ffffff;
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
}

.navigation {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;
    font-weight: 400;
    color: #ffffff;
    list-style: none;
    display: inline-block;
}

.main1 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;
    font-weight: 400;
    color: #ffffff;
}

.selected {
    background-color: rgb(17, 102, 9);
    color: red;
    cursor: not-allowed;
}

.card-header {
    background-color: #198754;
    color: #ffffff;
}

.text-green {
    text-decoration: none;
    padding: "20px";
    font-size: 1.0rem;
    text-anchor: middle;
    border: 10px;
}