.footer {
    /* background: linear-gradient(90deg,rgb(36, 16, 211) 0%,rgb(18, 11, 139) 100%,); */
   /* background-color:#cad3d0; */
    /* height: 25px;
   
    justify-content: center;*/
    align-items: left;
    font-size: 1.4rem; 
    color: rgb(4, 5, 5);
    display: flex;

}
.row1{
    padding: 2px;
    width: 100%;
}
.link{
    color: black;
    text-decoration: none;
    padding: 2px;
    align-items:left ;
    
}
.liststyled{
    align-content: left;
    align-items: left;

}
ul{
    list-style: none;
    padding: 20px;
    display: inline-block; 
	text-align: left; 
}
.footer{
    background-image: linear-gradient(0deg, rgba(171, 172, 171, 0.863), rgba(185, 185, 185, 0.3)), url("https://cdn.turkishairlines.com/m/6245e067ad8d9cc8/original/1400-660-jpg.jpg");
    /* background-size:cover; */
    width: 100%;
}
address{
    list-style: none;
    padding: 20px;
    display: inline-block; 
	text-align: left; 
    color:black
}
.p{
    text-align:left;
    color: black;
}